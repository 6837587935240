
.slider-frame {
  transform: none;
}

.svg-inline--fa {
  height: 2em;
}

.slider-control-centerleft > button {
  background: none;
  border: none;
  padding-left: 1rem;
}

.slider-control-centerright > button {
  background: none;
  border: none;
  padding-right: 1rem;
}

.uitk-loading-indicator {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}

.uitk-loading-indicator > div > div > div > div > svg > rect {
  fill: #c25609 !important;
}

.uitk-loading-indicator > div > div > div > div > svg > path {
  fill: #c25609 !important;
}

.uitk-loading-indicator > div > div > div > div > svg > g > rect {
  fill: #c25609 !important;
}

.uitk-loading-indicator > div > div > div > div > svg > g > path {
  fill: #c25609 !important;
}

.uitk-loading-indicator {
  padding-top: 0;
}
