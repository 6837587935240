::placeholder,
:-ms-input-placeholder {
  color: #6e7898;
}

.frf-modal-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: absolute;
  right: 25px;
  bottom: 108px;
  background: #ffffff;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  max-width: 380px;
  width: 80%;
  max-height: 70vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: scroll;
  border-radius: 4px;
  font-size: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.frf-modal-first-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid rgba(#6e7898, 0.15);

  .frf-modal-title {
    font-weight: 500;
  }

  .frf-modal-close {
    font-size: 14px;
    cursor: pointer;
  }
}

.frf-modal-content-container {
  padding: 24px;
}

.frf-modal-input-group {
  display: flex;
  flex-direction: column;
  padding-top: 2px;
  padding-bottom: 10px;
}

.frf-modal-label {
  color: #6e7898;
  padding-bottom: 8px;
  font-size: 14px;
  display: inline-block;
  font-weight: 500;
}

.frf-modal-input {
  padding: 12px 20px;
  border-radius: 4px;
  margin-bottom: 12px;
  border: 1px solid rgba(#6e7898, 0.15);
  box-shadow: none;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  font-weight: 500;

  &::placeholder {
    color: #6e7898;
    font-weight: 500;
  }
}

.frf-modal-feedback-types {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  .frf-modal-feedback-type {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    overflow-wrap: anywhere;
    max-width: 100/3;

    padding: 12px;
    text-align: center;
    border: 1px solid rgba(#6e7898, 0.15);
    cursor: pointer;
    margin-right: 5px;

    &:first-of-type {
      border-radius: 4px 0px 0px 4px;
    }

    &:last-of-type {
      border-radius: 0px 4px 4px 0px;
    }
  }
}

.frf-modal-button {
  padding: 16px 30px 16px;
  outline: 0;
  border-radius: 4px;
  border: 1px solid rgba(#6e7898, 0.15);
  margin-top: 16px;
  cursor: pointer;
  width: 100%;

  .frf-modal-button-loader {
    display: inline-block;
    border: 0.133 * 16px solid #6e7898;
    border-radius: 16px;
    width: 16px;
    height: 16px;
    animation: spin 0.5s linear infinite;
  }
}

.frf-water {
  text-align: center;
  margin-top: 24px;
  color: #6e7898;
  font-size: 12px;
  font-weight: 500;

  a {
    color: #00000000;
    text-decoration: none;

    &:hover {
      color: rgb(0, 93, 255);
    }
  }
}

@media (max-width: 300px) {
  .frf-modal-feedback-types {
    flex-wrap: wrap;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

:root {
  --feedbackfin-bg-color: #4d1d1d;
  --feedbackfin-text-color: 240, 100%, 10%;
  --feedbackfin-shadow: rgba(7, 0, 20, 0.06) 0px 0px 0px 1px,
    rgba(7, 0, 20, 0.1) 0px 10px 15px -3px, rgba(7, 0, 20, 0.05) 0px 4px 6px,
    rgba(7, 0, 20, 0.05) 0px 30px 40px;
  --feedbackfin-border-radius: 1rem;

  --feedbackfin-title-weight: 600;
  --feedbackfin-button-weight: 500;

  --feedbackfin-button-opacity: 0.04;
  --feedbackfin-button-opacity-hover: 0.08;
  --feedbackfin-button-opacity-active: 0.12;
  --feedbackfin-button-transition: background-color 0.2s ease-in-out,
    opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  --feedbackfin-icon-button-opacity: 0.38;

  --feedbackfin-primary-color: #6a35ff;
  --feedbackfin-primary-color-text: #fff;

  --feedbackfin-outline-opacity: 0.12;
  --feedbackfin-focus-color: var(--feedbackfin-primary-color);
  --feedbackfin-focus-shadow: 0 0 0 2px var(--feedbackfin-bg-color),
    0 0 0 4px var(--feedbackfin-focus-color);
}

#feedbackfin__container {
  position: fixed;
  z-index: 9999;

  width: 19rem;
  height: 15rem;
  box-sizing: border-box;

  background-color: var(--feedbackfin-bg-color);
  box-shadow: var(--feedbackfin-shadow);
  border-radius: var(--feedbackfin-border-radius);

  padding: 0.75rem 1rem;

  font-family: "OputmSans";
  font-size: 1rem;
  color: hsl(var(--feedbackfin-text-color));
}

#feedbackfin__container * {
  box-sizing: border-box;
}
#feedbackfin__container *:focus {
  outline: none;
}
#feedbackfin__container *:focus-visible {
  outline: none;
  box-shadow: var(--feedbackfin-focus-shadow);
}

.feedbackfin__button {
  background-color: hsla(
    var(--feedbackfin-text-color),
    var(--feedbackfin-button-opacity)
  );
  border: none;
  border-radius: calc(var(--feedbackfin-border-radius) / 2);
  padding: 0;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  font: inherit;
  font-weight: var(--feedbackfin-button-weight);
  color: inherit;
  min-height: 2rem;
  line-height: 2rem;

  transition: var(--feedbackfin-button-transition);
  
  &:hover {
    box-shadow: 0 0 0 2px #2407e5;
    color: #2407e5;
  }
}
.feedbackfin__button:hover,
.feedbackfin__button:focus-visible {
  background-color: hsla(
    var(--feedbackfin-text-color),
    var(--feedbackfin-button-opacity-hover)
  );
}
.feedbackfin__button:active {
  background-color: hsla(
    var(--feedbackfin-text-color),
    var(--feedbackfin-button-opacity-active)
  );
  transform: scale(0.97);
  transition-duration: 0s;
}

#feedbackfin__title {
  margin: 0 0 0.75rem;

  font-size: 1.25rem;
  font-weight: var(--feedbackfin-title-weight);
  text-align: center;
  line-height: normal;
}

.feedbackfin__radio-label {
  width: 100%;
  height: 7.5rem;
  margin: 1rem 0;

  line-height: 1;
  flex-direction: column;
  gap: 0.5rem 0.25rem;
}
.feedbackfin__radio:focus + .feedbackfin__radio-label {
  box-shadow: var(--feedbackfin-focus-shadow);
}
.feedbackfin__radio-icon {
  margin-bottom: 0.5rem;
  font-size: 3rem;
}