html, body, #root, #root > div {
    height: 100%;
    margin: 0;
    box-sizing: border-box;
     
      background-repeat: repeat;  
  }
  /* .react-draggable {
    /* transform: none !important; */
  /* } */ 
  .widgetWrapper {
    height: 300px;
    display: inline-flex;
    flex-direction: column;
    background: lightgray;
    padding: 8px 32px;
  }
  .noWidgets {
    text-align: center;
    padding-top: 200px;
  }
  .widgetRnDWrapper {
    position: relative;
  }
  .settingsPanel {
    position: absolute;
    right: 0;
    height: 100%;
    background: yellow;
    top: 0;
    width: 25%;
  }
  .widgetWrapperDiv {
    width: 100%;
    height: 100%;
  }
  .widgetConnectorNewDiv {
    width: 100%;
  }
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input#border-radius[type=number]::-webkit-outer-spin-button,
input#border-radius[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: auto;
  opacity: 1;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.rndDiv.react-draggable.react-draggable-dragged{
  outline: 3px solid #156dcf !important;
}

.TabbedPanelcomponent__Container-sc-1gf0ofu-0.fxpqIJ, .TabbedPanelcomponent__StyledTabbedPanel-sc-1gf0ofu-1.hYGIfd {
  height: inherit;
}
.uitk-tabbed-panel-button:focus, .uitk-tabbed-panel-button:hover {
  box-shadow: 0 0 0 3px #ff612b !important;
}
.uitk-tabbed-panel-button:after {
  box-shadow: 0 4px 0 0 #ff612b !important;
}
.TabbedPanelcomponent__StyledTabbedPanel-sc-1gf0ofu-1 {
  height: 100%;
}
.TabbedPanelcomponent__TabbedContent-sc-1gf0ofu-5 {
  display: none;
} 
.kmiJsG:focus, .kmiJsG:hover, .dA-dTvT:focus, .dA-dTvT:hover
{
  box-shadow: 0 0 0 3px #64636f !important;
}
.jREXvv{
  display: contents;
}

#headerSection {
  box-shadow: 0 4px 5px rgba(182, 182, 182, 0.75);
}

  @media only screen and (max-width: 1280px) {
    .rndDiv.react-draggable-overwrite.react-draggable {
    left: 0px !important;
    width: 1280px !important;
  }
}
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
background-color: #105bc8 !important;
color: #ffffff !important;
}
.uitk-panel .uitk-panel-header {
  padding: 0 1rem 0 1rem;
}

.copy-url-toast {
  font-family: 'OptumSans';
  background: #105bc8;
  color: white;
  border-radius: 100px;
  text-align: center;
  width: 16rem;
  font-size: larger;
}

.Toastify__toast-container--top-center {
  transform: scale(0.65, 0.65);
}